
import { Helmet } from 'react-helmet'

import './App.css'

const TITLE = 'jamfam.dev - Coming Soon'

function App() {
  return (
    <>
      <Helmet>
        <title>{ TITLE }</title>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
        <link rel="manifest" href="/site.webmanifest"/>
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bd570"/>
        <meta name="msapplication-TileColor" content="#161616"/>
        <meta name="theme-color" content="#161616"/>
      </Helmet>
    <div className="background container">
      <div class='vertical-center'>
        <h1 data-text="Welcome to the family." className="display-4 text-primary glitch">Welcome to the family.</h1>
      </div>
    </div>
    </>
  );
}

export default App;
